/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hace unos días que escribí hablando de la ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/2012/07/14/estandares-de-alimentacion-ii-microusb/"
  }, "alimentación mediante MicroUSB"), " y el USB en general y pocos días me llegó la Newsletter de Elektor con ", React.createElement(_components.a, {
    href: "http://www.elektor.es/noticias/usb-de-100-w.2223204.lynkx"
  }, "la noticia"), " de las nuevas especificaciones de USB 2.0 y 3.0 para soportar hasta 100 Watts."), "\n", React.createElement(_components.p, null, "Para lograr que esta gran potencia no fría los USB ni los cables se han propuesto dos soluciones complementarias, la primera utilizar cables certificados para estas altas potencias y aumentar la tensión suministrad por el USB que pasará  de los 5V actuales a tensiones de hasta 20V."), "\n", React.createElement(_components.p, null, "Se han definido 5 perfiles para las diferentes potencias que sean necesarias en cada dispositivo:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Perfil 1 capaz de suministrar 5V a 2.0A."), "\n", React.createElement(_components.li, null, "Perfil 2 capaz de suministrar 5V a 2.0A o 12v @1.5A."), "\n", React.createElement(_components.li, null, "Perfil 3 capaz de suministrar 5V a 2.0A o 12V a 3A."), "\n", React.createElement(_components.li, null, "Perfil 4 capaz de suministrar 5V a 2.0A, 12V y 20V a 3A."), "\n", React.createElement(_components.li, null, "Perfil 5 capaz de suministrar 5V a 2.0A, 12V y 20V a 5A."), "\n"), "\n", React.createElement(_components.p, null, "Con este aumento tan importante de la potencia de los USB no sólo se podrá alimentar dispositivos directamente desde el PC que antes no se podía, como pantallas, discos duros que ahora necesitan dos USB y muchos otros. Si no que se podrá cargar los ordenadores portátiles mediante USB y muchos otros casos donde se podrá utilizar, que lo harán convertirse en un estándar de carga, eliminando los cargadores con conectores de una única marca que siempre tratan de imponer los fabricantes."), "\n", React.createElement(_components.p, null, "Más información: ", React.createElement(_components.a, {
    href: "http://www.techspot.com/news/49511-usb-power-delivery-spec-capable-of-100w-could-make-chargers-obsolete.html"
  }, "http://www.techspot.com/news/49511-usb-power-delivery-spec-capable-of-100w-could-make-chargers-obsolete.html")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
